import React from "react";

import { plantName } from "./helpers";

import plant from "../images/plant.svg";
import basil from "../images/plants/basil.png";
import lollorosso from "../images/plants/lollorosso.png";
import rubinette from "../images/plants/lollorosso.png";
import mint from "../images/plants/mint.png";
import rocket from "../images/plants/rocket.png";
import romana from "../images/plants/romana.png";

export default function PlantIcon({ plantType }) {
  const altName = plantName(plantType);
  switch (plantType) {
    case "basil":
      return <img className="planttype" src={basil} alt={altName} />;
    case "lollo-rosso":
      return <img className="planttype" src={lollorosso} alt={altName} />;
    case "rubinette":
      return <img className="planttype" src={rubinette} alt={altName} />;
    case "mint":
      return <img className="planttype" src={mint} alt={altName} />;
    case "rocket":
      return <img className="planttype" src={rocket} alt={altName} />;
    case "romana":
      return <img className="planttype" src={romana} alt={altName} />;
    default:
      return <img className="planttype" src={plant} alt={altName} />;
  }
}

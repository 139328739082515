import React from "react";
import { Link } from "react-router-dom";

import { parseDeviceState } from "./helpers";

import lightIcon from "../images/light.svg";
import waterIcon from "../images/water.svg";
import orientationIcon from "../images/orientation.svg";

export default function DashButtons({ deviceState, logBook }) {
  const { water, light, orientation } = parseDeviceState(deviceState);
  return (
    <div className="dashbuttonsview">
      <Link to="/light" className="greenloopbutton">
        <img src={lightIcon} alt="Light" />
        <span>{light}</span>
      </Link>
      <Link to="/water" className="greenloopbutton">
        <img src={waterIcon} alt="Water" />
        <span>{water}</span>
      </Link>
      <Link to={`/plant/${orientation}`} className="greenloopbutton">
        <img src={orientationIcon} alt="Orientation" />
        <span>Pod {orientation}</span>
      </Link>
    </div>
  );
}

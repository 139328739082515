import React from "react";
import { useLocation } from "react-router-dom";
import { deviceUUID, uuidForDeviceNumber, checkPreP8 } from "./helpers";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CheckAuth({ onAuthSuccess }) {
  let query = useQuery();

  const deviceFromQuery = query.get("p");
  const tokenFromQuery = query.get("t");
  const deviceFromLocalStorage = deviceUUID();
  const tokenFromLocalStorage = localStorage.getItem("greenloop-token");

  const failedAuth = (
    <div className="card">
      <div className="autherror">
        <div className="formheader">Auth failed...</div>
        <div className="formrow">
          <span>Write to paul@greenloop.io in order to get access.</span>
        </div>
      </div>
    </div>
  );

  if (tokenFromQuery === btoa(uuidForDeviceNumber(deviceFromQuery))) {
  // Check if token from querystring matches
  localStorage.setItem(
      "greenloop-device",
      uuidForDeviceNumber(deviceFromQuery)
    );
    localStorage.setItem("greenloop-token", tokenFromQuery);
    localStorage.setItem("greenloop-preP8", checkPreP8(deviceFromQuery));
    setTimeout(() => onAuthSuccess("query"), 1);
    return null;
  } else if (tokenFromQuery || deviceFromQuery) {
    // deny if query params exist but are wrong
    return failedAuth;
  } else if (tokenFromLocalStorage === btoa(deviceFromLocalStorage)) {
    // check if localstorage is exists and matches
    setTimeout(() => onAuthSuccess("localStorage"), 1);
    return null;
  }

  return failedAuth;
}

function deviceNameForUUID(deviceUuid) {
  if (deviceUuid === "150e29229523aec0a45d27ea6edb402c") {
    return "P5";
  }

  if (deviceUuid === "43706dfd1319623dfb9c4b9042a37f52") {
    return "P6";
  }

  if (deviceUuid === "af90d2984b54f6815c9e33d77d096f2a") {
    return "P7";
  }

  if (deviceUuid === "b928dcab3b813802747fd595e8b050c2") {
    return "P7.3";
  }

  if (deviceUuid === "873bfb767b44ece4349cbafc08369842") {
    return "P7.3v2";
  }

  return deviceUuid;
}

export function uuidForDeviceNumber(deviceNumber) {
  if (deviceNumber === "5") {
    return "150e29229523aec0a45d27ea6edb402c";
  }
  if (deviceNumber === "6") {
    return "43706dfd1319623dfb9c4b9042a37f52";
  }
  if (deviceNumber === "7") {
    return "af90d2984b54f6815c9e33d77d096f2a";
  }
  if (deviceNumber === "7.3") {
    return "b928dcab3b813802747fd595e8b050c2";
  }
  if (deviceNumber === "7.3v2") {
    return "873bfb767b44ece4349cbafc08369842";
  }
  if (deviceNumber === "7.3v2") {
    return "873bfb767b44ece4349cbafc08369842";
  }
  return deviceNumber;
}

export function checkPreP8(deviceNumber) {
  return uuidForDeviceNumber(deviceNumber) !== deviceNumber;
}

export function isPreP8() {
  return localStorage.getItem("greenloop-preP8") !== "false";
}

export const deviceUUID = () => {
  return (
    localStorage.getItem("greenloop-device") ||
    "af90d2984b54f6815c9e33d77d096f2a"
  );
};

export const thingName = () => deviceUUID();

export const deviceName = () => {
  return deviceNameForUUID(deviceUUID());
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const fetchJson = (path, options) => {
  let url = `https://99ctq7bslk.execute-api.eu-central-1.amazonaws.com/prod${path}`;
  if (isPreP8()) {
    url = `https://1xi88uzddj.execute-api.eu-central-1.amazonaws.com/dev/api${path}`;
  }

  return fetch(url, {
    headers: { "Content-Type": "application/json" },
    ...options,
  }).then((response) => response.json());
};

export const sendCommand = (device, command, payload) => {
  console.log("SENDING", command);
  return fetch(
    "https://1xi88uzddj.execute-api.eu-central-1.amazonaws.com/dev/api/dashboardtunnel",
    {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ device, command, payload }),
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const PLANTS = {
  basil: "Basilikum",
  "holy-basil": "Heiliger Basilikum",
  lavender: "Lavendel",
  "lemon-basil": "Zitronenbasilikum",
  "lollo-rosso": "Lollo Rosso",
  lovage: "Liebstöckel",
  oregano: "Oregano",
  "red-amaranth": "Roter Amaranth",
  "red-basil": "Roter Basilikum",
  rocket: "Rucola",
  romana: "Romana",
  rubinette: "Roter Eichblattsalat",
  "thai-basil": "Thai Basilikum",
  tropaeolum: "Kapuzinerkresse",
  wallonne: "Wallonne",
  empty: "Leer",
};

export const plantName = (plantType) => {
  return PLANTS[plantType];
};

export const LIGHT_SETTINGS = {
  NIGHT: "NIGHT",
  GROW: "GROW",
  AMBIENTE: "AMBIENTE",
};

export const lightSettingName = (setting) => {
  switch (setting && setting.toUpperCase()) {
    case LIGHT_SETTINGS.NIGHT:
      return "Nacht";
    case LIGHT_SETTINGS.GROW:
      return "Grow Licht";
    case LIGHT_SETTINGS.AMBIENTE:
      return "Ambiente";
    default:
      return "";
  }
};

export function statusForLevel(waterLevel) {
  if (waterLevel < 206) {
    return "8L"; // 100%
  }
  if (waterLevel < 430) {
    return "6L"; // 75%
  }
  if (waterLevel < 633) {
    return "4L"; // 50%
  }
  if (waterLevel < 806) {
    return "3L"; // 40%
  }
  if (waterLevel < 922) {
    return "2,5L"; // 30%
  }
  if (waterLevel < 1033) {
    return "1,8L"; // 25%
  }
  if (waterLevel < 1133) {
    return "1,4L"; // 18%
  }
  if (waterLevel < 1242) {
    return "1L"; // 11%
  }
  if (waterLevel < 1344) {
    return "0,5L"; // 5%
  }
  if (waterLevel < 1429) {
    return "0,1L"; // 1%
  }
  return "0L"; // 0%
}

export function parseDeviceState(state) {
  if (isPreP8()) {
    return {
      light: lightSettingName(state.LIGHT_SETTING),
      water: statusForLevel(state.VALUES.backTankLevel),
      orientation: state.BOX_AT_PLANTING_POSITION,
    };
  }
  return {
    light: lightSettingName("not implemented"),
    water: state.waterTankLevel + "%",
    orientation: state.podAtNullPosition + 1,
  };
}

import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { deviceUUID, sendCommand, parseDeviceState } from "./helpers";

import lightIcon from "../images/light.svg";
import waterIcon from "../images/water.svg";
import orientationIcon from "../images/orientation.svg";

export default function Water({ deviceState }) {
  const { water } = parseDeviceState(deviceState);
  const [ loading, setLoading ] = useState(false);

  const startCommand = async (command) => {
    setLoading(true);
    await sendCommand(deviceUUID(), command);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  console.log("Hi, water!", water);

  return (
    <Fragment>
      <div className="card__header">
        <div className="plantdetails__header">
          <img src={waterIcon} alt="Water" /> <span>Tank Level {water}</span>
        </div>
        <Link to="/">x</Link>
      </div>
      <div className="plantdetails">
        <div className="plantcontrols">
          <Fragment>
            <button
              className="greenloopbutton greenloopbutton--huge"
              onClick={() => {
                startCommand("ROTATE_BOX");
              }}
              disabled={loading}
            >
              <img src={orientationIcon} alt="Rotate" />
              <span>Rotate</span>
            </button>
            <button
              className="greenloopbutton greenloopbutton--huge"
              onClick={() => {
                startCommand("SHOWTANKLEVEL");
              }}
              disabled={loading}
            >
              <img src={lightIcon} alt="Light" />
              <span>Show Back Tank Level</span>
            </button>
            <button
              className="greenloopbutton greenloopbutton--huge"
              onClick={() => {
                startCommand("FILL_WHEEL_TANK");
              }}
              disabled={loading}
            >
              <img src={waterIcon} alt="Water" />
              <span>Fill Wheel Tank</span>
            </button>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import { ReactComponent as LoopSvg } from "../images/loop.svg";
import { LIGHT_SETTINGS } from "./helpers";

let lastPod = null;
export default function Loop({ orientation, light, logBook, onPodClick }) {
  const match = useRouteMatch("/:path/:pod");

  useEffect(() => {
    if (!logBook) {
      return;
    }
    Object.keys(logBook).forEach((pod) => {
      const plantTypeImage = `#${logBook[pod].plantType}-plant`;
      const image = document.getElementById(`plantimage-${Number(pod) - 1}`);

      if (image.getAttribute("xlink:href") !== plantTypeImage) {
        image.setAttribute("xlink:href", plantTypeImage);
      }
    });
  }, [logBook]);

  useEffect(() => {
    const lightGradientStart = document.querySelector("#light-gradient-start");
    const lightGradientEnd = document.querySelector("#light-gradient-end");
    if (light === LIGHT_SETTINGS.NIGHT) {
      lightGradientStart.setAttribute('stop-opacity', '0');
      lightGradientStart.setAttribute('stop-color', '#000000');
      lightGradientEnd.setAttribute('stop-opacity', '0');
      lightGradientEnd.setAttribute('stop-color', '#000000');
    }
    if (light === LIGHT_SETTINGS.GROW) {
      lightGradientStart.setAttribute('stop-opacity', '0.7');
      lightGradientStart.setAttribute('stop-color', '#FFFB00');
      lightGradientEnd.setAttribute('stop-opacity', '0');
      lightGradientEnd.setAttribute('stop-color', '#FFFB00');
    }
    if (light === LIGHT_SETTINGS.AMBIENTE) {
      lightGradientStart.setAttribute('stop-opacity', '0.7');
      lightGradientStart.setAttribute('stop-color', '#FFE2C7');
      lightGradientEnd.setAttribute('stop-opacity', '0');
      lightGradientEnd.setAttribute('stop-color', '#FFE2C7');
    }
  }, [light]);

  const currentPod =
    match && match.params && match.params.pod ? match.params.pod : null;

  if (currentPod !== lastPod) {
    const boxes = document.querySelectorAll(".boxcontent");
    boxes.forEach((box) => (box.style.fill = "#FFFFFF"));
    const box = document.querySelector(`#box${currentPod - 1} > .boxcontent`);
    if (box) {
      box.style.fill = "#d5d5d5";
    }
  }
  lastPod = currentPod;

  const findClickedElement = (e) => {
    if (!e.target || !e.target.parentElement || !e.target.parentElement.id) {
      return;
    }

    const clickedElement = e.target.parentElement.id;

    if (clickedElement.startsWith("box")) {
      const clickedBox = Number(("" + clickedElement).slice(3));
      onPodClick(clickedBox + 1);
    }
  };

  return (
    <div
      id="loop"
      width="100%"
      height="100%"
      aria-label="image"
      onClick={findClickedElement}
    >
      <LoopSvg style={{ transform: `rotate(${15 * (orientation - 1)}deg)` }} />
    </div>
  );
}

import React, { Fragment, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import lightIcon from "../images/light.svg";
import { deviceUUID, sendCommand, lightSettingName } from "./helpers";

const hoursUntilMidnight = 22 - new Date().getHours();

export default function LightSettings() {
  const { setting } = useParams();
  const [ loading, setLoading ] = useState(false);
  const history = useHistory();

  const activateSetting = async (e, duration, setting) => {
    setLoading(true);
    await sendCommand(deviceUUID(), "LIGHT_SETTING", { duration, setting });
    setTimeout(() => {
      setLoading(false);
      history.push("/");
    }, 2000);
  };

  return (
    <Fragment>
      <div className="card__header">
        <div className="plantdetails__header">
          <img src={lightIcon} alt="Light" />{" "}
          <span>{lightSettingName(setting.toUpperCase())} aktivieren</span>
        </div>
        <Link to="/">x</Link>
      </div>
      <div className="lightview">
        <button
          className="greenloopbutton greenloopbutton--primary"
          onClick={(e) => activateSetting(e, 2 * 60, setting.toUpperCase())}
          disabled={loading}
        >
          <span>Für 2h</span>
        </button>
        <button
          className="greenloopbutton greenloopbutton--primary"
          onClick={(e) => activateSetting(e, 4 * 60, setting.toUpperCase())}
          disabled={loading}
        >
          <span>Für 4h</span>
        </button>
        <button
          className="greenloopbutton greenloopbutton--primary"
          onClick={(e) =>
            activateSetting(e, hoursUntilMidnight * 60, setting.toUpperCase())
          }
          disabled={loading}
        >
          <span>Bis Morgen</span>
        </button>
      </div>
    </Fragment>
  );
}

import React, { Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import orientationIcon from "../images/orientation.svg";
import harvestIcon from "../images/harvest.svg";
import editIcon from "../images/edit.svg";
import plantIcon from "../images/plant.svg";
import { deviceUUID, sendCommand, parseDeviceState, isPreP8, fetchJson, thingName } from "./helpers";

let highlightTimeout;
async function highlightPod(orientation, currentPod) {
  if (highlightTimeout) {
    clearTimeout(highlightTimeout);
  }

  let ledBoard = orientation - currentPod;
  if (ledBoard < 0) {
    ledBoard += 24;
  }

  // "LEDBOARDHIGHLIGHT:%d, %d,%d,%d,%d"
  if (isPreP8()) {
    await sendCommand(deviceUUID(), `LEDBOARDHIGHLIGHT:${ledBoard},0,50,200,50`);
  } else {
    await fetchJson(`/ctrl/highlight?thing=${thingName()}&pod=${ledBoard}`);
  }

  console.log('LEDBOARD HIGHLIGHT for 30s, board: ', ledBoard);
  highlightTimeout = setTimeout(() => {
    console.log('LEDBOARD HIGHLIGHT reset to preset');
    highlightTimeout = undefined;
    sendCommand(deviceUUID(), `LIGHT_PRESET`);
  }, 30000);
}

async function resetLight() {
  if (highlightTimeout) {
    await sendCommand(deviceUUID(), `LIGHT_PRESET`);
    clearTimeout(highlightTimeout);
    highlightTimeout = undefined;
  }
}

export default function Plant({ deviceState, logBook }) {
  const { pod } = useParams();
  const { orientation } = parseDeviceState(deviceState);
  useEffect(() => {
    if (pod && orientation) {
      if (document.hidden) {
        // if the loop rotates, orientation changes, so this useEffect is triggered again
        // but if the app is in background, the user did not click a different pod, so we should not highlight one.
        console.log('not changing light, app is in background');
        return;
      }

      highlightPod(orientation, pod);
    }
  }, [orientation, pod])

  let currentPod = pod || orientation;
  const plant = logBook[currentPod];

  return (
    <Fragment>
      <div className="card__header">
        <div className="plantdetails__header">
          <img src={orientationIcon} alt="Orientation" /> <span>Pod {currentPod}</span>
        </div>
        <Link to="/" onClick={() => resetLight()}>x</Link>
      </div>
      <div className="plantview">
        <div className="plantdetails">
          <div className="plantcontrols">
            {plant.plantId ? (
              <Fragment>
                <Link
                  to={`/harvest/${currentPod}`}
                  className="greenloopbutton greenloopbutton--huge"
                >
                  <img src={harvestIcon} alt="Scissors" />
                  <span>Ernten!</span>
                </Link>
                <Link
                  to={`/changeplant/${currentPod}`}
                  className="greenloopbutton greenloopbutton--huge"
                >
                  <img src={editIcon} alt="Edit" />
                  <span>Bearbeiten</span>
                </Link>
              </Fragment>
            ) : (
              <Link
                to={`/newplant/${currentPod}`}
                className="greenloopbutton greenloopbutton--huge"
              >
                <img src={plantIcon} alt="Pflanze" />
                <span>Pflanzen!</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

import React, { useState, Fragment } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import "./App.css";

import DashButtons from "./components/DashButtons";
import FetchState from "./components/FetchState";
import Light from "./components/Light";
import Plant from "./components/Plant";
import ChangePlant from "./components/ChangePlant";
import NewPlant from "./components/NewPlant";
import HarvestPlant from "./components/HarvestPlant";
import { deviceName, LIGHT_SETTINGS, parseDeviceState } from "./components/helpers";
import Loop from "./components/Loop";
import CheckAuth from "./components/CheckAuth";
import PodDetails from "./components/PodDetails";
import LightSettings from "./components/LightSettings";
import Water from "./components/Water";

import logo from "./images/logo.svg";

function App() {
  const [appState, setAppState] = useState("auth");
  const [deviceState, setDeviceState] = useState(null);
  const [logBook, setLogBook] = useState(null);
  const [refetchHandler, registerHandler] = useState(null);
  const history = useHistory();

  const handlePodClick = (pod) => {
    history.push("/plant/" + pod);
  };

  if (appState === "auth") {
    return (
      <Fragment>
        <main>
          <div className="navbar">
            <div>
              <img width="20px" src={logo} alt="Greenloop Logo" />
            </div>
            <div>{deviceName()}</div>
          </div>
          <div className="loop">
            <Loop light={LIGHT_SETTINGS.NIGHT} orientation={0} onPodClick={() => {}} />
          </div>
        </main>
        <footer>
          <CheckAuth
            onAuthSuccess={() => {
              setAppState("connecting");
              history.replace("/");
            }}
          />
        </footer>
      </Fragment>
    );
  }

  if (appState === "connecting" && deviceState && logBook) {
    setAppState("online");
  }

  let routes = (
    <Switch>
      <Route exact path="/">
        <DashButtons deviceState={deviceState} logBook={logBook} />
      </Route>
      <Route exact path="/light">
        <Light deviceState={deviceState} logBook={logBook} />
      </Route>
      <Route exact path="/light/:setting">
        <LightSettings deviceState={deviceState} logBook={logBook} />
      </Route>
      <Route exact path="/water">
        <Water deviceState={deviceState} logBook={logBook} />
      </Route>
      <Route path="/plant/:pod">
        <Plant deviceState={deviceState} logBook={logBook} />
      </Route>
      <Route path="/newplant/:pod">
        <NewPlant
          deviceState={deviceState}
          logBook={logBook}
          refetchHandler={refetchHandler}
        />
      </Route>
      <Route path="/harvest/:pod">
        <HarvestPlant logBook={logBook} refetchHandler={refetchHandler} />
      </Route>
      <Route path="/changeplant/:pod">
        <ChangePlant deviceState={deviceState} logBook={logBook} />
      </Route>
      <Route path="*">
        <DashButtons deviceState={deviceState} logBook={logBook} />
      </Route>
    </Switch>
  );

  let podDetailRoutes = (
    <Switch>
      <Route path="/plant/:pod">
        <PodDetails deviceState={deviceState} logBook={logBook} />
      </Route>
    </Switch>
  );

  if (appState === "connecting") {
    routes = (
      <Switch>
        <Route path="*">Verbindung wird hergestellt...</Route>
      </Switch>
    );

    podDetailRoutes = null;
  }

  if (appState === "offline") {
    routes = (
      <Switch>
        <Route path="*">Greenloop ist offline... connecting...</Route>
      </Switch>
    );

    podDetailRoutes = null;
  }

  let orientation = 0;
  let light = LIGHT_SETTINGS.NIGHT;
  if (deviceState) {
    const state = parseDeviceState(deviceState);
    orientation = state.orientation;
    light = deviceState.LIGHT_SETTING;
  }

  return (
    <Fragment>
      <main>
        <div className="navbar">
          <div>
            <img width="20px" src={logo} alt="Greenloop Logo" />
          </div>
          <div>{deviceName()}</div>
        </div>
        <div className="loop">
          <Loop
            orientation={orientation}
            light={light}
            logBook={logBook}
            onPodClick={handlePodClick}
          />
        </div>
        {podDetailRoutes}
      </main>
      <footer>
        <div className="card">{routes}</div>
      </footer>
      <FetchState
        onDeviceState={(state) => setDeviceState(state)}
        onLogBook={(logBook) => setLogBook(logBook)}
        onDeviceOffline={() => setAppState("offline")}
        refetchLogBook={(handler) => registerHandler(() => handler)}
      />
    </Fragment>
  );
}

export default App;

import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import lightIcon from "../images/light.svg";
import { deviceUUID, sendCommand, parseDeviceState } from "./helpers";

export default function Light({ deviceState, logBook }) {
  const { light } = parseDeviceState(deviceState);

  const [running, setRunning] = useState(false);

  const showEffect = (e, effectName) => {
    setRunning(true);
    sendCommand(deviceUUID(), effectName);
    setTimeout(() => setRunning(false), 2000);
  };

  return (
    <Fragment>
      <div className="card__header">
        <div className="plantdetails__header">
          <img src={lightIcon} alt="Light" /> <span>{light} aktiv</span>
        </div>
        <Link to="/">x</Link>
      </div>
      <div className="lightview">
        <div className="lightsettings">
          <div className="lightsettings__header">Einstellung</div>
          <Link to="/light/grow" className="greenloopbutton greenloopbutton--primary">
            <span>Grow Licht</span>
          </Link>
          <Link to="/light/ambiente" className="greenloopbutton greenloopbutton--primary">
            <span>Abendstimmung</span>
          </Link>
          <Link to="/light/night" className="greenloopbutton greenloopbutton--primary">
            <span>Licht ausschalten</span>
          </Link>
        </div>
        <div className="lightsettings">
          <div className="lightsettings__header">Effekte</div>
          <button
            className="greenloopbutton greenloopbutton--primary"
            onClick={(e) => showEffect(e, "LIGHT_ANIM_EIFFEL")}
            disabled={running}
          >
            <span>Tour Eiffel</span>
          </button>
          <button
            className="greenloopbutton greenloopbutton--primary"
            onClick={(e) => showEffect(e, "SMOOTH")}
            disabled={running}
          >
            <span>Basil Dance</span>
          </button>
          <button
            className="greenloopbutton greenloopbutton--primary"
            onClick={(e) => showEffect(e, "COLORFUL")}
            disabled={running}
          >
            <span>Kaminfeuer</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
}

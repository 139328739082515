import React, { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { deviceUUID, uuidv4, fetchJson, PLANTS, isPreP8, thingName } from "./helpers";
import { ACTIONS } from "./book";

export default function ChangePlant({ deviceState, logBook }) {
  const { pod } = useParams();

  const [viewState, setViewState] = useState("form");
  const plantType = useRef(null);
  const plantAge = useRef(null);

  const plant = logBook[pod];

  const handleSave = () => {
    setViewState("loading");

    const event = {
      thing: thingName(),
      plantId: uuidv4(),
      pod: Number(pod),
      plantType: plantType.current.value,
      action: ACTIONS.PLANT_CHANGED,
      payload: { age: Number(plantAge.current.value) },
    };

    if (isPreP8()) {
      event.deviceUUID = deviceUUID();
      delete event.thing;
    }

    if (plantType.current.value === "empty") {
      event.action = ACTIONS.PLANT_REMOVED;
      event.payload = { age: null };
      event.plantId = null;
    }

    fetchJson("/plantlog/event", {
      method: "POST",
      body: JSON.stringify(event),
    }).then(() => setViewState("success"));
  };

  return (
    <div className="plantdetails">
      <div className="formheader">Pod {pod}</div>
      <div className="formrow">
        <span>Welche Pflanze wächst jetzt in {pod}?</span>
      </div>
      <div className="formrow">
        <span className="formlabel">Pflanze</span>
        <select
          className="forminput"
          defaultValue={plant.plantType}
          disabled={viewState === "loading" || viewState === "success"}
          ref={plantType}
        >
          {Object.entries(PLANTS).map(([plantType, plantName]) => (
            <option key={plantType} value={plantType}>
              {plantName}
            </option>
          ))}
        </select>
      </div>
      <div className="formrow">
        <span className="formlabel">Alter</span>
        <input
          className="forminput"
          type="number"
          defaultValue={plant.payload.age}
          placeholder="in Tagen"
          disabled={viewState === "loading" || viewState === "success"}
          ref={plantAge}
        ></input>
      </div>
      {viewState === "success" ? (
        <div className="plantcontrols">
          <Link to="/">
            <button className="greenloopbutton">
              <span>Zurück</span>
            </button>
          </Link>
          <button className="greenloopbutton greenloopbutton--primary" disabled>
            <span>Gespeichert!</span>
          </button>
        </div>
      ) : (
        <div className="plantcontrols">
          <Link to="/">
            <button
              className="greenloopbutton"
              disabled={viewState === "loading"}
            >
              <span>Abbrechen</span>
            </button>
          </Link>
          <button
            className="greenloopbutton greenloopbutton--primary"
            onClick={handleSave}
            disabled={viewState === "loading"}
          >
            <span>{viewState === "loading" ? "Laden..." : "Speichern"}</span>
          </button>
        </div>
      )}
    </div>
  );
}

import { differenceInDays } from 'date-fns';

import { deviceUUID, fetchJson, isPreP8 } from "./helpers";

let book = [];
export function fetchBook() {
  let url = `/plantlog/book?thing=${deviceUUID()}`;
  if (isPreP8()) {
    url = `/plantlog/book?device=${deviceUUID()}`;
  }

  return fetchJson(url).then(
    (events) => {
      book = events;
      book.reverse(); // events come most recent first, but we want oldest first, so newer events overwrite older
      return currentPlantState(book);
    }
  );
}

export const ACTIONS = {
  PLANT_PLANTED: "plant_planted",
  PLANT_CHANGED: "plant_changed",
  PLANT_HARVESTED: "plant_harvested",
  PLANT_REMOVED: "plant_removed",
};

const isPlantEvent = (action) =>
  [ACTIONS.PLANT_CHANGED, ACTIONS.PLANT_PLANTED].indexOf(action) !== -1;

const isHarvestEvent = (action) => [ACTIONS.PLANT_HARVESTED, ACTIONS.PLANT_REMOVED].indexOf(action) !== -1;

const currentPlantState = (book) => {
  const state = {};

  for (let i = 1; i <= 24; i++) {
    state[i] = { plantType: "empty", plantId: null };
  }

  book.forEach((event) => {
    if (isPlantEvent(event.action)) {
      let ts = event.ts;
      if (isPreP8()) {
        ts = event.recorded;
      }

      const sinceEvent = differenceInDays(new Date(), new Date(ts));
      const plantAge = Number(event.payload.age) + sinceEvent;

      state[event.pod] = event;
      state[event.pod].plantAge = plantAge;
    }

    if (isHarvestEvent(event.action)) {
      state[event.pod] = { plantType: "empty", plantId: null };
    }
  });

  return state;
};

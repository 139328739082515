import React from "react";
import { useParams } from "react-router-dom";

import PlantIcon from "./PlantIcon";
import { plantName, parseDeviceState } from "./helpers";

function badgeForState(state) {
  if (state === "harvest") {
    return "Bereit zum Ernten";
  }
  if (state === "seed") {
    return "Keimphase";
  }
  if (state === "empty") {
    return "Bereit zum Einpflanzen";
  }
  return "Wachstumsphase";
}

export default function PodDetails({ deviceState, logBook }) {
  const { pod } = useParams();
  const { orientation } = !deviceState ? { orientation: 0 } : parseDeviceState(deviceState);

  let currentPod = pod || orientation;
  const plant = logBook[currentPod];

  let plantDescription = "Bereit für eine Pflanze!";
  let state = "empty";
  let plantType = "empty";
  if (plant.plantId) {
    const plantAge = plant.plantAge;
    plantDescription = `${plantName(plant.plantType)}, ${plantAge} Tage`;
    state = "grow";
    plantType = plant.plantType;

    if (plantAge > 34) {
      state = "harvest";
    }

    if (plantAge < 7) {
      state = "seed";
    }
  }

  return (
    <div className="poddetails">
      <PlantIcon plantType={plantType} />
      <div>{plantDescription}</div>
      <div className="splitbadge">
        <div className="badge badge__start badge--description">
          Pod {currentPod}
        </div>
        <span className={`badge badge__end badge--${state}`}>
          {badgeForState(state)}
        </span>
      </div>
    </div>
  );
}

import React, { useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { deviceUUID, uuidv4, fetchJson, PLANTS, isPreP8, thingName } from "./helpers";
import { ACTIONS } from "./book";


export default function NewPlant({ deviceState, logBook, refetchHandler }) {
  const { pod } = useParams();
  const history = useHistory();

  const [viewState, setViewState] = useState("form");
  const plantType = useRef(null);
  const plantAge = useRef(null);

  const handleSave = () => {
    if (plantType.current.value === 'empty') {
      plantType.current.required = true;
      return;
    }

    setViewState("loading");
    const event = {
      thing: thingName(),
      plantId: uuidv4(),
      pod: pod,
      plantType: plantType.current.value,
      action: ACTIONS.PLANT_PLANTED,
      payload: { age: Number(plantAge.current.value) },
    };

    if (isPreP8()) {
      event.deviceUUID = deviceUUID();
      delete event.thing;
    }

    fetchJson("/plantlog/event", {
      method: "POST",
      body: JSON.stringify(event),
    }).then(() => { refetchHandler(); setViewState("success"); history.push(`/plant/${pod}`) });
  };

  return (
    <div className="plantdetails">
      <div className="formheader">Pod {pod}</div>
      <div className="formrow">
        <span>Was hast du in {pod} gepflanzt?</span>
      </div>
      <div className="formrow">
        <span className="formlabel">Pflanze</span>
        <select
          className="forminput"
          defaultValue="empty"
          disabled={viewState === "loading"}
          ref={plantType}
        >
          <option value="empty">Sorte auswählen...</option>
          {Object.entries(PLANTS).map(([plantType, plantName]) => (
            <option key={plantType} value={plantType}>{plantName}</option>
          ))}
        </select>
      </div>
      <div className="formrow">
        <span className="formlabel">Alter</span>
        <input
          className="forminput"
          type="number"
          defaultValue=""
          placeholder="in Tagen"
          disabled={viewState === "loading"}
          ref={plantAge}
        ></input>
      </div>
      {viewState === "success" ? (
        <div className="plantcontrols">
          <Link to="/">
            <button className="greenloopbutton">
              <span>Zurück</span>
            </button>
          </Link>
          <button className="greenloopbutton greenloopbutton--primary" disabled>
            <span>Gespeichert!</span>
          </button>
        </div>
      ) : (
        <div className="plantcontrols">
          <Link to="/">
            <button
              className="greenloopbutton"
              disabled={viewState === "loading"}
            >
              <span>Abbrechen</span>
            </button>
          </Link>
          <button
            className="greenloopbutton greenloopbutton--primary"
            onClick={handleSave}
            disabled={viewState === "loading"}
          >
            <span>{viewState === "loading" ? "Laden..." : "Speichern"}</span>
          </button>
        </div>
      )}
    </div>
  );
}

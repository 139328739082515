import { useEffect } from "react";
import { deviceUUID, isPreP8 } from "./helpers";
import { fetchBook } from "./book";

function deviceStateUrl() {
  const device = deviceUUID();
  if (isPreP8()) {
    return `https://${device}.resindevice.io/api/state`;
  }
  return `https://99ctq7bslk.execute-api.eu-central-1.amazonaws.com/prod/state/latest?thing=${device}`
}

export default function FetchState({
  onDeviceState,
  onLogBook,
  onDeviceOffline,
  refetchLogBook,
}) {
  useEffect(() => {
    refetchLogBook(() => {
      fetchBook().then((logBook) => onLogBook(logBook));
    });

    fetchBook().then((logBook) => onLogBook(logBook));
    setInterval(() => {
      fetch(deviceStateUrl())
        .then((response) => response.json())
        .then((state) => onDeviceState(state));
    }, 10000);

    fetch(deviceStateUrl())
      .then((response) => response.json())
      .then((state) => onDeviceState(state))
      .catch((e) => {
        console.log('Could not reacht device: ', e);
        onDeviceOffline();
      })
  }, []); // eslint-disable-line

  return null;
}
